<template>
  <div v-if="isGrid">
    <section-element
      v-if="layout.header && layout.header.visible"
      :text="'News'"
      :icon="['fas', 'rss']"
      :layout="layout.header"
      :skeleton="layout.skeleton"
    ></section-element>
    <live-news-grid :layout="this.layout.grid" :content="content">
    </live-news-grid>
  </div>
  <live-news-element
    v-else
    :content="content"
    :layout="layout"
  ></live-news-element>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "LiveNews",
  mixins: [layoutMixin],
  components: {
    LiveNewsElement: () => import("@/components/livenews/LiveNewsElement"),
    LiveNewsGrid: () => import("@/components/livenews/LiveNewsGrid"),
    SectionElement: () => import("@/components/core/SectionElement"),
  },
  props: {
    content: [Object, Array],
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },
  computed: {
    isGrid: function() {
      if (this.layout && this.layout.grid && this.layout.grid.visible) {
        return true;
      }
      return false;
    },
  },
  methods: {
    //
  },
};
</script>
